import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/fonts.css";
import "./styles/main.css";

import Navigation from "./components/Navigation";
import ScrollToTop from "./components/ScrollToTop";

import Home from "./pages/Home";
import Cyber from "./pages/Cyber";
import Resources from "./pages/Resources";
import CyberPatriot from "./pages/Resources/CyberPatriot";
import Minecraft from "./pages/Minecraft";
import idkraft from "./pages/Minecraft/idkraft";
import report from "./pages/Minecraft/report";
import feedback from "./pages/Minecraft/feedback";
import Privacy from "./pages/Privacy";
import Error404 from "./pages/Error404";

class App extends Component {
    render() {
        return (
            <div className="App h-100">
                <Navigation />
                <Route render={({location}) => (
                    <div>
                        <ScrollToTop />
                        <TransitionGroup className="h-100">
                            <CSSTransition key={location.pathname} timeout={550} classNames="main-ripple-transition">
                                <Switch location={location}>
                                    <Route exact path="/" component={Home} />
                                    <Route exact path="/cyber" component={Cyber} />
                                    <Route exact path="/resources" component={Resources} />
                                    <Route exact path="/resources/cyberpatriot" component={CyberPatriot} />
                                    <Route exact path="/cyberpatriot">
                                        <Redirect to="/resources/cyberpatriot" />
                                    </Route>
                                    <Route exact path="/minecraft/idkraft" component={idkraft} />
                                    <Route exact path="/idkraft">
                                        <Redirect to="/minecraft/idkraft" />
                                    </Route>
                                    <Route exact path="/minecraft/report" component={report} />
                                    <Route exact path="/report">
                                        <Redirect to="/minecraft/report" />
                                    </Route>
                                    <Route exact path="/minecraft/feedback" component={feedback} />
                                    <Route exact path="/feedback">
                                        <Redirect to="/minecraft/feedback" />
                                    </Route>
                                    <Route exact path="/privacy" component={Privacy} />
                                    <Route component={Error404} />
                                </Switch>
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                )} />
            </div>
        );
    }
}

export default App;