import $ from "jquery";
import { WOW } from "wowjs";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";

import seo_image from "../../img/icon512.png";

// values for meta tags
var title = "101sec - CyberPatriot";
var description = "Quick links to CyberPatriot resources";
var canon_url = "https://101sec.net/resources/cyberpatriot";

class CyberPatriot extends Component {
    componentDidMount() {
        // initialize wow.js for scroll animations
        const wow = new WOW({live: false});
        var scrolled = false;
        $(window).on("scroll", function() {
            if (!scrolled) {
                scrolled = true;
                wow.init();
            }
        })
    }
    render() {
        return (
            <div className="main-page h-100">
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={seo_image} />
                    <meta property="og:url" content={canon_url} />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:site" content="@101sec" />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                    <meta name="twitter:image" content={seo_image} />
                    <meta name="robots" content="index, follow" />
                </Helmet>
                <header className="container-fluid min-vh-100 bg-light">
                    <div className="container h-100">
                        <div className="row main-navbar-spacer"></div>
                        <div className="row align-items-center main-fade-in-up-animation">
                            <div className="col-md-8">
                                <h1>CyberPatriot</h1>
                                <p>Quick links to the private materials for the 101sec CyberPatriot team.</p>
                                <br />
                                <h3>Windows and Windows Server</h3>
                                <ul>
                                    <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/12jBiFerfRm8dQxB3PSbLDI3qo8RQ95SH?usp=sharing">Google Drive (Windows)</a></li>
                                    <ul>
                                        <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/1-RE0sYH2HQpJ_w9fBBgTUkaHIVR0uzSHB3joM5IskRM/edit?usp=sharing">Team Roster and IDs</a></li>
                                        <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1wDSHEtljXLjOTA6RsbLXwZLh_p-VJhab2oL-WJMPAec/edit?usp=sharing">Basic Windows Security Checklist</a></li>
                                        <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1ghWPaWPHY9DgcJY5xFGfR1KHCcvfzpECrn0plPG-juU/edit">Advanced Windows Security Checklist</a></li>
                                        <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1o5gO-YUohQa9qEtaakOlDdMHEZpag-R3flJTfq2IrOA/edit?usp=sharing">Afterthought Checklist</a></li>
                                        <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/11RIhrqYy-UghHH3TXEisVQEoXAvHG9qA?usp=sharing">Archives</a></li>
                                    </ul>
                                    <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://github.com/login">GitHub Login</a></li>
                                    <ul>
                                        <li><a className="button-link" target="_blank" rel="noopener noreferrer" href ="https://github.com/101secNetwork/cyberpatriot/tree/master/Windows">101sec GitHub (Windows)</a></li>
                                    </ul>
                                </ul>
                                <h3>Ubuntu and Debian</h3>
                                <ul>
                                    <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/12XWllR2NKkfsjFm3cgE9OuWe-GVda7SS?usp=sharing">Google Drive (Linux)</a></li>
                                    <ul>
                                        <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/1-RE0sYH2HQpJ_w9fBBgTUkaHIVR0uzSHB3joM5IskRM/edit?usp=sharing">Team Roster and IDs</a></li>
                                        <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1EdWidh4vV1ZYZ3VKLC1iZIYaD-ewDo5xRb09CQy7FgI/edit?usp=sharing">Ubuntu Security Checklist</a></li>
                                        <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1o5gO-YUohQa9qEtaakOlDdMHEZpag-R3flJTfq2IrOA/edit?usp=sharing">Afterthought Checklist</a></li>
                                        <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1nooZS696_zt_ZVBm08WXjrJRFvcWfRSZ0O2VqzYPQ8c/edit?usp=sharing">Linux Baselining Guide</a></li>
                                        <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/11RIhrqYy-UghHH3TXEisVQEoXAvHG9qA?usp=sharing">Archives</a></li>
                                    </ul>
                                    <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://github.com/login">GitHub Login</a></li>
                                    <ul>
                                        <li><a className="button-link" target="_blank" rel="noopener noreferrer" href ="https://github.com/101secNetwork/cyberpatriot/tree/master/Linux/">101sec GitHub (Linux)</a></li>
                                    </ul>
                                </ul>
                                <h3>Cisco</h3>
                                <ul>
                                    <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://150566673.netacad.com/login/canvas">CP-XIII Cisco Competition Portal</a></li>
                                    <ul>
                                        <li>Use your team's credentials located <a className="button-link" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/1-RE0sYH2HQpJ_w9fBBgTUkaHIVR0uzSHB3joM5IskRM/edit?usp=sharing">here</a>.</li>
                                    </ul>
                                    <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1h4YRu2LbOTeHkTWzgfHkuUJvwQOQUHsfyJraYj0FUqA/edit?usp=sharing">Cisco Checklist</a></li>
                                    <li><a className="button-link" target="_blank" rel="noopener noreferrer" href="https://www.netacad.com/portal/saml_login">NetAcad</a></li>
                                    <ul>
                                        <li>Only for accessing training materials, which is helpful during the competition. Use your personal account to login.</li>
                                    </ul>
                                </ul>
                            </div>
                            <div className="col-md-4">
                            <iframe title="CyberPatriot Vibe" src="https://open.spotify.com/embed/playlist/49vUroxOgAdcblVeTBvCmr" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                            </div>
                        </div>
                        <div className="row main-navbar-spacer"></div>
                    </div>
                </header>
                <Footer />
            </div>
        )
    }
}

export default CyberPatriot;