import $ from "jquery";
import { WOW } from "wowjs";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";

import seo_image from "../../img/icon512.png";
import thonkman_character from "../../img/thonk_floating.png";
import xythereon_character from "../../img/xythereon_shovel.png";

// values for meta tags
var title = "101sec - Minecraft";
var description = "Join the server at play.101sec.net";
var canon_url = "https://101sec.net/minecraft";

class Minecraft extends Component {
    componentDidMount() {
        // initialize wow.js for scroll animations
        const wow = new WOW({live: false});
        var scrolled = false;
        $(window).on("scroll", function() {
            if (!scrolled) {
                scrolled = true;
                wow.init();
            }
        })
    }
    render() {
        return (
            <div className="main-page h-100">
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={seo_image} />
                    <meta property="og:url" content={canon_url} />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:site" content="@101sec" />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                    <meta name="twitter:image" content={seo_image} />
                    <meta name="robots" content="index, follow" />
                </Helmet>
                <header className="container-fluid h-100 bg-light">
                    <div className="container h-100">
                        <div className="row h-25"></div>
                        <div className="row h-50 align-items-center justify-content-center">
                            <div className="col">
                                <h1 className="text-center main-fade-in-up-animation">Title.</h1>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-fluid">
                    <div className="container py-5">
                        <div className="row main-mc-listing align-items-center justify-content-center wow main-fade-in-up-animation">
                            <div className="col-md-5 text-center order-md-2">
                                <img src={thonkman_character} class="mw-100 main-mc-characters" alt="Thonkman" />
                            </div>
                            <div className="col-md-5">
                                <h2 className="font-weight-bold">IDKRAFT</h2>
                                <p>A completely vanilla survival experience with friends. Protected by a whitelist to ensure safe play.</p>
                            </div>
                        </div>
                        <div className="row main-mc-listing align-items-center justify-content-center wow main-fade-in-up-animation">
                            <div className="col-md-5 text-center">
                                <img src={xythereon_character} class="mw-100 main-mc-characters" alt="Xythereon" />
                            </div>
                            <div className="col-md-5">
                                <h2 className="font-weight-bold">XYTHEREON</h2>
                                <p>Casual survival gameplay, complete with land claims, warps, economy, and other plugins!</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Minecraft;